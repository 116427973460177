(window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
(window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
(window as any).__zone_symbol__UNPATCHED_EVENTS = ['scroll', 'mousemove', 'pointerover', 'pointermove']; // disable patch specified eventNames


// // (window as any).__Zone_disable_EventTarget = true;

// (window as any).__Zone_ignore_on_properties = [
//     {
//       target: HTMLElement.prototype,
//       ignoreProperties: ['click']
//     }
//   ];


// (window as any).__zone_symbol__UNPATCHED_EVENTS = ['click', 'scroll', 'mousemove', 'pointerover', 'pointermove']; // disable patch specified eventNames
